.navbar-toggler {
  background: #fff;
}
.navbar-nav a {
  transition: all 0.5s ease-in-out;
  color: #fff !important;
  position: relative;
  margin-left: 30px;
  padding: 0px !important;
  font-size: 1.3rem;
  font-weight: 500;
  /*text-shadow: 2px 2px 4px #0e0631;*/
  text-decoration: none;
}

/* .navbar-expand-lg .navbar-nav .nav-link:hover {
    background-color: #C62828;
    color: #fff;

}
.navbar-expand-lg .navbar-nav .nav-link.active {
    background-color: #C62828;
    color: #fff;

} */
.navbar-nav a:after {
  background: #fff;
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 2px;
  margin-top: 0px;
  transition: 0.3s all ease;
}
.navbar-nav a:hover:after {
  width: 100%;
}
.navbar-nav a.active {
  color: #f28106 !important;
}
.navbar-nav a.active:after {
  background: #f28106;
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 2px;
  margin-top: 0px;
  transition: 0.3s all ease;
}
@media only screen and (max-width: 767px) {
  .navbar-collapse {
    background: #0e0631;
    opacity: 0.8;
  }
  .navbar-nav a {
    margin: 0px;
    padding: 3px 5px !important;
  }
}
