.header-bg {
  background: #0e0631;
  position: fixed;
  z-index: 99;
  opacity: 0.9;
  transition: height 0.2s ease-in-out;
}
h1.heading {
  background-color: #0e0631;
  color: #fff;
  padding: 20px;
  margin-top: -100px;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  opacity: 0.8;
  font-size: 1.7rem;
  position: absolute;
  justify-content: center;
}
.subheading {
  font-weight: 700;
  font-size: 3rem;
  text-align: center;
  padding: 55px 0px 25px 0px;
  background: url(../public/images/hline.png) no-repeat center bottom;
  margin-bottom: 1.5rem;
}
.subheading span {
  font-weight: 300;
}
.subheading2 {
  font-weight: 700;
  font-size: 46px;
  text-align: left;
  color: #fff;
}
.subheading2 span {
  font-weight: 300;
}
.content {
  padding-left: 300px;
  margin-bottom: 10px;
}
ul.list-items {
  list-style: none;
}
ul.list-items li {
  margin: 10px 0px 10px 0px;
  position: relative;
  padding-left: 20px;
}
ul.list-items li a {
  color: #000;
}
ul.list-items li::before {
  content: "";
  position: absolute;
  width: 15px;
  left: 0%;
  height: 15px;
  background-image: url(../public/images/orange-right.svg);
  background-size: cover;
  background-position: center;
  top: 5px;
}
.our-vision {
  width: 100%;
  background-image: url(../public/images/fluidimg.jpg);
  background-size: cover;
  background-position: center;
  min-height: 400px;
  padding-top: 50px;
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .header-bg {
    position: relative;
    height: 100px !important;
  }
  .subheading {
    font-size: 2rem;
    margin-bottom: .5rem;
    padding: 25px 0px 25px 0px;
  }
  h1.heading {
    margin-top: -80px;
    width: 140px;
    height: 80px;
    padding: 5px; 
    font-size: 1.2rem;
  }
}
