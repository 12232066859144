.footer {
  background-color: #221a46;
}
.site-info {
  text-align: center;
  font-size: 0.8rem;
  padding: 8px 0;
  background: #0e0631;
  color: #fff;
  width: 100%;
}
.footer-nav {
  list-style: none;
  margin-top: 10px;
  padding: 20px 0px;
}
.footer-nav  a {
  display: inline-block;
  color: #fff;
  margin-right: 20px;
  position: relative;
  transition: all 0.3s ease;
  text-decoration: none;
}

.footer-nav a:hover {
  color: #f28106;
}
.footer-nav a::after {
  content: ""; /* Add content to pseudo-element */
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: #f28106;
  width: 0px; /* Adjust width to fill the entire width of the list item */
  display: block;
  transition: all 0.3s ease;
}
.footer-nav a:hover::after {
  width: 100%; /* Adjust width on hover */
}
.footer-contact {
  color: #fff;
}
.footer-contact h3 {
  font-size: 1.2rem;
  margin: 0rem;
}
.footer-contact p a {
  color: #fff;
  text-decoration: none;
  position: relative;
}
.footer-contact p a::after {
  content: ""; /* Add content to pseudo-element */
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: #fff;
  width: 0px; /* Adjust width to fill the entire width of the list item */
  display: block;
  transition: all 0.3s ease;
}
.footer-contact p a:hover::after {
  width: 100%;
}
ul.stayconnect {
  margin: 0px auto 0px auto;
  padding: 0px;
  display: flex;
  justify-content: end;
  align-items: center;
}
ul.stayconnect li {
  display: block;
  margin-left: 4px;
}

/*ul.stayconnect li a{display:block;width:40px;height:40px; background:url(/public/socialmediaicons.png) no-repeat left top;}
ul.stayconnect li a:hover {background-position:left bottom;transition: .3s all ease;}
ul.stayconnect li.two a{background-position:-44px top;}
ul.stayconnect li.two a:hover{background-position:-44px bottom;}
ul.stayconnect li.three a{background-position:-88px top;}
ul.stayconnect li.three a:hover{background-position:-88px bottom;}
ul.stayconnect li.gplus a{background-position:-132px top;}
ul.stayconnect li.gplus a:hover{background-position:-132px bottom;}
ul.stayconnect li.twitter a{background-position:-175px top;}
ul.stayconnect li.twitter a:hover{background-position:-175px bottom;}
ul.stayconnect li.fb a{background-position:-220px top;}
ul.stayconnect li.fb a:hover{background-position:-220px bottom;}
ul.appoitment {margin:90px auto 0px auto;padding:0px;display:inline-block;}
ul.appoitment li {display:block; float:left; font-size:16px; font-weight:600; line-height:30px; margin-left:120px; text-align:center;}
ul.appoitment li:first-child{margin-left:0px;}
ul.appoitment li  span {font-size:14px;font-weight:400;display:block;}
ul.appoitment li .icons {width:189px; height:189px; margin:0px auto;}*/
