.carousel .slide .legend {
  bottom: 100px;
  left: 5%;
  margin-left: 0px;
  width: 40%;
  border-radius: 10px;
  background: #0e0631;
  color: #fff;
  padding: 8px;
  font-size: 12px;
  text-align: left;
  font-style: italic;
  transition: opacity 0.35s ease-in-out;
  font-size: 2.5rem;
  opacity: 0.6;
}
.carousel:hover .slide .legend {
  background: #0e0631;
  opacity: 0.8;
}
.carousel .slide .legend span {
  font-weight: 700;
  font-style: normal;
  display: block;
  color: #f28106;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .carousel .slide .legend {
    font-size: 1.5rem;
    bottom: 5px;
    width: 60%;
  }
}
@media only screen and (max-width: 767px) {
  .carousel .slide .legend {
    font-size: 1rem;
    bottom: 5px;
    width: 60%;
  }
}
